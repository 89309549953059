.planet-tooltip {
  opacity: 1 !important;
  font-size: 0.813rem;
  font-family: "Exo", sans-serif;
  user-select: none;
  -webkit-user-select: none;
  &.bs-tooltip-top {
    margin-bottom: 0.25rem; // fit button translateY
  }
}

.planet-tooltip .tooltip-inner {
  background-color: #fff;
  color: map-get($planet-colors, "grey-dark");
  box-shadow: 0px 5px 15px #55677729;
}
.planet-tooltip.big .tooltip-inner {
  max-width: 350px;
}
.planet-tooltip.s-big .tooltip-inner {
  max-width: 450px;
}

.planet-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #fff;
}
.planet-tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #fff;
}
.planet-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #fff;
}
.planet-tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #fff;
}

.support-detail-tip {
  .tooltip-inner {
    min-width: 125px;
  }
}

.area-tip {
  &.bs-tooltip-top {
    margin-bottom: 0 !important;
  }
  .tooltip-inner {
    min-width: 250px;
  }
}

.quality-tip {
  &.bs-tooltip-top {
    margin-bottom: 0 !important;
  }
  .tooltip-inner {
    min-width: 400px;
  }
}

.right-tip {
  margin-left: 2px !important;
  .tooltip-inner {
    max-width: 300px;
  }
}

.index-tip {
  .tooltip-inner {
    max-width: 300px;
  }
}

.pressure-tip {
  .tooltip-inner {
    max-width: 250px;
  }
}

.pressure-tip-bottom {
  .tooltip-inner {
    min-width: 300px;
  }
}

.tenderWinner-tip {
  .tooltip-inner {
    min-width: 100px;
  }
}

.addTag-tip {
  .tooltip-inner {
    min-width: 225px;
  }
}
.addEngTag-tip {
  .tooltip-inner {
    min-width: 310px;
  }
}

.sdg-tip {
  .tooltip-inner {
    max-width: 250px;
  }
}

.esg-tip {
  .tooltip-inner {
    max-width: 250px;
  }
}
