/* You can add global styles to this file, and also import other style files */

// BOOTSTRAP
@import "style/bootstrap-themed";

// base
@import "style/base";

// styled ng-boostrap tooltip
@import "style/planet-tooltip.scss";
