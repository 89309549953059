@import "planet-colors";

html,
body {
  min-height: 100%;
  height: fit-content;
  min-width: 100%;
  width: fit-content;
}
body {
  margin: 0;
  font-family: "Exo", sans-serif;
  font-size: 16px;
  background-color: map-get($planet-colors, "grey-lighter");
  &.bg-striped {
    background: linear-gradient(45deg, #f3f5f7 25%, #fff 0, #fff 50%, #f3f5f7 0, #f3f5f7 75%, #fff 0);
    background-size: 90px 90px;
  }
}

.not-selectable {
  user-select: none;
  -webkit-user-select: none;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-grab {
  cursor: grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;
}

.v-truncate-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; // max lines displayed
}
.v-truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.v-truncate-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

// custom scrollbar
.cm-scrollbar::-webkit-scrollbar {
  width: 7px;
  height: 7px; // horizontal
  border-radius: 7px;
}
.cm-scrollbar::-webkit-scrollbar-track {
  background-color: map-get($planet-colors, "grey-lighter");
  border-radius: 7px;
}
.cm-scrollbar::-webkit-scrollbar-thumb {
  background-color: map-get($planet-colors, "grey-light");
  border-radius: 7px;
  &:hover {
    background-color: map-get($planet-colors, "grey");
  }
}

// custom modal style
.cm-modal .modal-content {
  background-color: map-get($planet-colors, "grey-lighter");
  padding: 1.5rem 1rem;
}

// ngb modal autofocus workaround
.modal__btn--hidden {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
}

.no-data {
  color: map-get($planet-colors, "grey-dark");
  font-size: 0.844rem;
  cursor: default;
}

// colors
.red {
  color: map-get($planet-colors, "red") !important;
}
.green {
  color: map-get($planet-colors, "green") !important;
}
.yellow {
  color: map-get($planet-colors, "yellow") !important;
}
.blue {
  color: map-get($planet-colors, "blue") !important;
}
.grey {
  color: map-get($planet-colors, "grey") !important;
}
.grey-dark {
  color: map-get($planet-colors, "grey-dark") !important;
}
.grey-darker {
  color: map-get($planet-colors, "grey-darker") !important;
}
.gold {
  color: map-get($planet-colors, "gold") !important;
}
.silver {
  color: map-get($planet-colors, "silver") !important;
}
.bronze {
  color: map-get($planet-colors, "bronze") !important;
}
.grey-default {
  color: map-get($planet-colors, "grey-default") !important;
}

// bg colors
.bg-red {
  background-color: map-get($planet-colors, "red") !important;
}
.bg-green {
  background-color: map-get($planet-colors, "green") !important;
}
.bg-yellow {
  background-color: map-get($planet-colors, "yellow") !important;
}
.bg-blue {
  background-color: map-get($planet-colors, "blue") !important;
}
.bg-grey {
  background-color: map-get($planet-colors, "grey") !important;
}
.bg-grey-dark {
  background-color: map-get($planet-colors, "grey-dark") !important;
}

.page-title {
  font-size: 1.625rem;
  font-weight: 700;
  color: map-get($planet-colors, "grey-darker");
  word-break: break-word;
  word-wrap: break-word;
  cursor: default;
}

.modal__title {
  font-size: 1.125rem;
  font-weight: 700;
  color: map-get($planet-colors, "grey-darker");
  word-break: break-word;
  word-wrap: break-word;
  cursor: default;
}

a.no-default-underline {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

@media print {
  @page {
    margin: 0;
  }

  body {
    background: none;
  }
}
