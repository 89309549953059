@font-face {
    font-family: "Exo";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Exo Regular"), url("Exo-Regular.woff") format("woff");
}

@font-face {
    font-family: "Exo";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Exo Medium"), url("Exo-Medium.woff") format("woff");
}

@font-face {
    font-family: "Exo";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Exo Bold"), url("Exo-Bold.woff") format("woff");
}
